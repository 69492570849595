import { useState } from "react";
import Slide from "./components/slide";
import Home from "./slides/home";
import Map from "./slides/maps";
import Schedule from "./slides/schedule";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Travel from "./slides/travel";
import { Analytics } from "@vercel/analytics/react";

setTimeout(() => {
  if (window.location.href.indexOf("#") >= 0) {
    let hash = window.location.href.split("#")[1];
    let hashTop = document.querySelector(`#${hash}`)?.getBoundingClientRect().y;
    if (hashTop) {
      window.scrollTo(0, hashTop);
    }
  }
}, 100);

export default function App() {
  let [nav, setNav] = useState("");

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Slide staticHeight id="home">
            <Home setNav={setNav} />
          </Slide>
          <Slide>
            <Travel setNav={setNav} />
          </Slide>
          <Slide>
            <Schedule setNav={setNav} />
          </Slide>
          <div style={{ height: "30px" }}></div>
        </>
      ),
    },
    {
      path: "/thingstodo",
      element: (
        <Slide>
          <Map setNav={setNav} />
        </Slide>
      ),
    },
  ]);

  return (
    <div className="App" style={{ margin: "0px", padding: "0px" }}>
      <nav>
        <div>
          <a href="/#home">E&M - 5.18.23</a>
        </div>
        <a
          href="/#travel"
          onClick={() => setNav("travel")}
          className={nav === "travel" ? "selected" : ""}
        >
          Travel
        </a>
        <a
          href="/#schedule"
          onClick={() => setNav("schedule")}
          className={nav === "schedule" ? "selected" : ""}
        >
          Schedule
        </a>
        <a
          href="/thingstodo"
          onClick={() => setNav("thingstodo")}
          className={nav === "thingstodo" ? "selected" : ""}
        >
          Things to do
        </a>
      </nav>
      <RouterProvider router={router}></RouterProvider>
      <Analytics />
    </div>
  );
}
