import { useInView } from "react-intersection-observer";
import Anchor from "../components/anchor";
import "./travel.css";

export default function Travel({ setNav }: { setNav: (a: string) => void }) {
  let { ref, inView } = useInView({ threshold: 0.5 });
  if (inView) {
    console.log("setting travel");
    setNav("travel");
  }

  return (
    <section ref={ref} className="travel">
      <Anchor id="travel" />
      <h1>Travel</h1>
      <div>
        <h2>Getting from the airport to Boulder</h2>
        <ul>
          <li>Car: ~40 minutes</li>
          <ul>
            <li>Uber/Lyft: $55 to $180</li>
          </ul>
          <li>Public Tranportation</li>
          <ul>
            <li>
              RTD{" "}
              <a
                target="_blank"
                href="https://www.rtd-denver.com/app/route/AB/schedule?direction=westbound"
              >
                Bus Route AB1
              </a>
              : ~60 minutes;{" "}
              <a
                target="_blank"
                href="https://www.rtd-denver.com/fares-passes/fares"
              >
                $10.50/each
              </a>
            </li>
            <li>
              Alternative:{" "}
              <a
                target="_blank"
                href="https://www.rtd-denver.com/fastracks/a-line"
              >
                A Line Train
              </a>{" "}
              -{" "}
              <a
                target="_blank"
                href="https://www.rtd-denver.com/app/route/FF1/schedule?direction=westbound"
              >
                RTD Route FF1
              </a>{" "}
              - 1h40m
            </li>
          </ul>
          <li>Shuttle - ~40-60min; $55 to $100</li>
          <ul>
            <li>
              <a target="_blank" href="https://bouldershuttle.com/rates/">
                Boulder Shuttle
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://eightblackairportshuttle.com/rates/"
              >
                Eight Black Airport Shuttle
              </a>
            </li>
          </ul>
        </ul>
      </div>
      <div>
        <h2>Getting around Boulder</h2>
        <div>
          Boulder has a great bike rental program called Boulder Bcycle <br />
          See their website to download their app, and for bicycle kiosk
          locations:{" "}
          <a target="_blank" href="https://boulder.bcycle.com/map-app">
            Boulder BCycle
          </a>
        </div>
      </div>
      <div>
        <h2>Contact us</h2>
        <ul>
          <li>
            <b>Address</b>
            <br />
            <a href="https://www.google.com/maps/dir/?api=1&destination=1255+Violet+Ave+Boulder+Co">
              1255 Violet Ave, Apt #202
              <br />
              Boulder, CO 80304
            </a>
          </li>
          <li>
            <b>Matt phone</b>
            <br />
            <a href="tel:6189108554">618-910-8554</a>
          </li>
          <li>
            <b>Erin phone</b>
            <br />
            <a href="tel:5702411915">570-241-1915</a>
          </li>
        </ul>
      </div>
    </section>
  );
}
