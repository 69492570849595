export default function Anchor({
  id,
  position,
}: {
  id: string;
  position?: string;
}) {
  return (
    <div style={{ position: "relative" }}>
      <span
        className="anchor"
        id={id}
        style={{ top: position ? position : "-70px" }}
      ></span>
    </div>
  );
}
