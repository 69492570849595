import { ReactNode } from "react";

export default function Slide({id, children, staticHeight}:{ id?: string, children?: ReactNode, staticHeight?: boolean }) {
  let slideStyle = {
    padding: "0px",
    margin: "0px",
    width: "100%",
    overflow: "hidden",
    height:"inherit",
    minHeight:"inherit"
  };
  if (staticHeight) {
    slideStyle.height = "100vh";
  } else {
    slideStyle.minHeight = "100vh";
  }
  return (
    <div
      id={id}
      style={{
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        ...slideStyle
      }}
    >
      {children}
    </div>
  );
}
