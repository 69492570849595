import mattAndErinPicture from "./../matt-and-erin.jpg";
import "./home.css";
import { useInView } from "react-intersection-observer";

export default function Home({ setNav }: { setNav: (arg: string) => void }) {
  let { ref, inView } = useInView({ threshold: 1 });
  if (inView) {
    setNav("home");
  }
  return (
    <>
      <img className="cover" src={mattAndErinPicture}></img>
      <h1 ref={ref}>
        <div className="description">
          <span className="description-small">
            After 10 years of adventures...
            <br />
          </span>
          We're eloping!
        </div>
        <div className="announce">
          Erin <span className="ampersand">&</span> Matt
        </div>
        <div className="wedding-date">5-18-23</div>
      </h1>
    </>
  );
}
