import { useCallback, useEffect, useState } from "react";
import { POI, ThingToDo, thingsToDoData } from "../data/thingstododata";
import "./maps.css";
import Anchor from "../components/anchor";

export default function Map({ setNav }: { setNav: (a: string) => void }) {
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
  const [map, setMap] = useState<google.maps.Map>();
  const [dataWithMarkers, setDataWithMarkers] = useState<ThingToDo[]>([]);

  async function initMap(): Promise<void> {
    setNav("thingstodo");

    const { Map, InfoWindow } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;

    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      "marker"
    )) as google.maps.MarkerLibrary;

    let localWindow = new InfoWindow();
    setInfoWindow(localWindow);

    let localMap = new Map(document.getElementById("map") as HTMLElement, {
      keyboardShortcuts: false,
      zoom: 12,
      gestureHandling: "greedy",
      center: { lat: 40.01559, lng: -105.26033 },
      disableDefaultUI: true,
      mapId: "6172791096ed50b6",
    });
    setMap(localMap);

    let t = thingsToDoData.map(({ category, icon, pois }) => {
      let poisWithMarkers = pois.map((poi) => {
        let markerDiv = document.createElement("div");
        markerDiv.innerHTML = `<span class="material-symbols-outlined">${
          icon ? icon : "location_on"
        }</span>`;
        const avMarker = new AdvancedMarkerElement({
          map: localMap,
          position: { lat: poi.lat, lng: poi.lng },
          content: markerDiv,
        });

        avMarker.addListener("click", () =>
          markerClick(localWindow, localMap, t, poi, markerDiv, avMarker)
        );
        return {
          ...poi,
          marker: avMarker,
          markerContent: markerDiv,
        };
      });

      return {
        category,
        pois: poisWithMarkers,
      };
    });
    setDataWithMarkers(t);
  }

  useEffect(() => {
    initMap();
  }, []);

  const [currentPOI, setCurrentPOI] = useState<POI | null>(null);

  const poiRef = useCallback((poi: HTMLDivElement) => {
    if (poi) {
      const y =
        poi.getBoundingClientRect().top +
        window.pageYOffset -
        window.innerHeight * 0.25 -
        90;
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
  }, []);

  function markerClick(
    w: google.maps.InfoWindow,
    m: google.maps.Map,
    dataWithMarkers: ThingToDo[],
    poi: POI,
    markerContent: HTMLElement,
    avMarker: google.maps.marker.AdvancedMarkerElement
  ) {
    dataWithMarkers.forEach((d) =>
      d.pois.forEach((p) =>
        p.markerContent ? (p.markerContent.className = "") : ""
      )
    );
    markerContent.className = "selected";
    setCurrentPOI(poi);
    w.close();
    w.setContent(poi.name);
    w.open(m, avMarker);
  }

  function selectLocation(poi: POI) {
    if (map) {
      map.panTo({ lng: poi.lng, lat: poi.lat });
    }
    if (poi.markerContent && poi.marker && infoWindow && map) {
      markerClick(
        infoWindow,
        map,
        dataWithMarkers,
        poi,
        poi.markerContent,
        poi.marker
      );
    }
  }

  return (
    <section
      className="maps"
      style={{
        margin: "0px",
        padding: "0px",
        position: "absolute",
        top: "calc(25vh + 80px)",
      }}
    >
      <div id="map" />
      {/* <div
        style={{
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          width: "100vw",
          backgroundColor: "pink",
          padding: "10px",
          color: "white",
          zIndex: "9999",
        }}
      >
        {dataWithMarkers.map(({ category }) => (
          <div>
            <a href={"#" + category}>{category}</a>
          </div>
        ))}
      </div> */}
      <div>
        {dataWithMarkers &&
          dataWithMarkers.map(({ category, pois }) => {
            return (
              <>
                <Anchor
                  id={category}
                  position={category == "Hiking" ? "-300px" : "-250px"}
                />
                <h1
                  style={{
                    width: "100vw",
                  }}
                >
                  {category}
                </h1>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "stretch",
                    justifyContent: "center",
                  }}
                >
                  {pois.map((poi) => {
                    return (
                      <div
                        ref={
                          currentPOI && poi.name == currentPOI.name
                            ? poiRef
                            : null
                        }
                        style={{
                          flex: 1,
                          maxWidth: "400px",
                          textAlign: "left",
                          padding: "20px",
                          backgroundColor: "white",
                          margin: "10px",
                          boxShadow:
                            currentPOI && poi.name == currentPOI.name
                              ? "0px 14px 28px 0px rgba(0,0,0,0.25), 0px 10px 10px 0px rgba(0,0,0,0.22)"
                              : "",
                        }}
                      >
                        <div>
                          <b>{poi.name}</b>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          {poi.info && (
                            <a target="_blank" href={poi.info}>
                              Info |{" "}
                            </a>
                          )}
                          <a target="_blank" href={poi.directions}>
                            Directions
                          </a>
                          <a
                            style={{ float: "right" }}
                            onClick={() => selectLocation(poi)}
                          >
                            View on map
                          </a>
                        </div>
                        <div style={{ padding: "20px 5px" }}>
                          {poi.description}
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <img src={poi.image} style={{ maxWidth: "300px" }} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
      </div>
    </section>
  );
}
