export type POI = {
  name: string;
  lat: number;
  lng: number;
  info?: string;
  directions: string;
  description: string;
  image: string;
  marker?: google.maps.marker.AdvancedMarkerElement;
  markerContent?: HTMLElement;
};

export type ThingToDo = {
  pois: POI[];
  category: string;
  icon?: string;
};

export let thingsToDoData: ThingToDo[] = [
  {
    category: "Hotels and Apartments",
    icon: "bed",
    pois: [
      {
        name: "Hyatt",
        description: "Hotel where most of the Lenahans are staying",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Hyatt+Place+Boulder+Pearl+Street",
        image:
          "https://assets.hyatt.com/content/dam/hyatt/hyattdam/images/2015/11/18/1544/Hyatt-Place-Boulder-Pearl-Street-P001-Front-of-Hotel.jpg/Hyatt-Place-Boulder-Pearl-Street-P001-Front-of-Hotel.16x9.jpg",
        lat: 40.0242133,
        lng: -105.2543597,
        info: "https://www.hyatt.com/",
      },
      {
        name: "Erin and Matt's apartment",
        description: "This is where we live!",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=1255+Violet+Ave",
        image:
          "https://img.firebrandlab.com/eyJidWNrZXQiOiJkeHAtZGgtcHJvZHVjdGlvbiIsImtleSI6IjVkOWVmZjkyMmJmNjRiMDAxMTIwMTViNi9hc3NldHMvNWQ4MGRjZTc3YjQ5ODMwMDEwMTA4ZGM2L2J1aWxkaW5ncy5qcGciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjE0NDAsImhlaWdodCI6ODEwfX19",
        lat: 40.0547724,
        lng: -105.2837547,
      },
    ],
  },
  {
    category: "Hiking",
    icon: "hiking",
    pois: [
      {
        name: "Chautauqua and Bluebell trail loop",
        lat: 39.99919,
        lng: -105.28149,
        info: "https://www.alltrails.com/explore/trail/us/colorado/chautauqua-and-bluebell-trail-loop",
        description:
          "1.6 miles, 459 feet elevation gain. A classic short (but tough) hike in Boulder. Great views of the flatirons.",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Chautauqua+Trailhead",
        image:
          "https://www.tripsavvy.com/thmb/2M5VuwfMxCiZE5PNjjT8Cdg0gvw=/2121x1414/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-1188176845-2d30cb3aa5b841bbb5182eb57f04bdcf.jpg",
      },
      {
        name: "Flatirons Vista Trail",
        lat: 39.92400771910729,
        lng: -105.2354899619105,
        info: "https://www.alltrails.com/trail/us/colorado/flatirons-vista-trail",
        description:
          "3.5 miles, 285 feet elevation gain. A scenic trail just south of Boulder with great views of the flatirons",
        directions:
          "https://www.google.com/maps/dir/Current+Location/39.92422,-105.23555",
        image:
          "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMzgxMDIwOTYvZDViYzA4ZmE0MWFiOGQyZDQ2NjQ0YzcxNWY1NWRmZmMuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjoyMDQ4LCJoZWlnaHQiOjIwNDgsImZpdCI6Imluc2lkZSJ9LCJyb3RhdGUiOm51bGwsImpwZWciOnsidHJlbGxpc1F1YW50aXNhdGlvbiI6dHJ1ZSwib3ZlcnNob290RGVyaW5naW5nIjp0cnVlLCJvcHRpbWlzZVNjYW5zIjp0cnVlLCJxdWFudGlzYXRpb25UYWJsZSI6M319fQ==",
      },
      {
        name: "Wonderland Lake Trail",
        info: "https://www.alltrails.com/trail/us/colorado/wonderland-lake-trail",
        description:
          "1.8 miles, 88 feet elevation gain. An easy, mostly flat trail around a lake near Matt and Erin's apartment in North Boulder",
        directions:
          "https://www.google.com/maps/dir/Current+Location/40.05086,-105.28265",
        lat: 40.05086,
        lng: -105.28265,
        image:
          "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvNTI3MjgzNTMvNjExNzEzZGEzYTI0MzhjNzFkNzMyNGY3NDM5MWNjODYuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjoyMDQ4LCJoZWlnaHQiOjIwNDgsImZpdCI6Imluc2lkZSJ9LCJyb3RhdGUiOm51bGwsImpwZWciOnsidHJlbGxpc1F1YW50aXNhdGlvbiI6dHJ1ZSwib3ZlcnNob290RGVyaW5naW5nIjp0cnVlLCJvcHRpbWlzZVNjYW5zIjp0cnVlLCJxdWFudGlzYXRpb25UYWJsZSI6M319fQ==",
      },
      {
        name: "Sage and Eagle Trail Loop",
        info: "https://www.alltrails.com/explore/trail/us/colorado/sage-and-eagle-trail-loop",
        description:
          "2.8 miles, 164 feet elevation gain. An easy, flat trail just north of town. Country vibes, frequently run into cows and prairie dogs.",
        directions:
          "https://www.google.com/maps/dir/Current+Location/40.08001,-105.26273",
        lat: 40.08001,
        lng: -105.26273,
        image:
          "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvNTQyNjkyNTEvNzJkZDE4ZDJmOGViMzg1NzQ4ZGJhYWRlYmE4ODE4NGQuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjoyMDQ4LCJoZWlnaHQiOjIwNDgsImZpdCI6Imluc2lkZSJ9LCJyb3RhdGUiOm51bGwsImpwZWciOnsidHJlbGxpc1F1YW50aXNhdGlvbiI6dHJ1ZSwib3ZlcnNob290RGVyaW5naW5nIjp0cnVlLCJvcHRpbWlzZVNjYW5zIjp0cnVlLCJxdWFudGlzYXRpb25UYWJsZSI6M319fQ==",
      },
    ],
  },
  {
    category: "Food",
    icon: "restaurant",
    pois: [
      {
        name: "Boulder Dushanbe Teahouse",
        lat: 40.01545,
        lng: -105.2772,
        description:
          "One of our must-sees in Boulder. Delicious Tea and appetizers.",
        info: "https://www.boulderteahouse.com/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Boulder+Dushanbe+Teahouse",
        image:
          "https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,h_1200,q_75,w_1200/v1/clients/boulder/Dushanbe_Teahouse_Patio_81815f13-0903-431e-86a6-964f9ca5dc07.jpg",
      },
      {
        name: "Rosetta Hall",
        lat: 40.01708,
        lng: -105.28067,
        info: "https://rosettahall.com/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Rosetta+Hall",
        description:
          "A dining hall with a wide variety of good tasting food and good atmosphere. Hang out on the rooftop for good views of Boulder.",
        image:
          "https://i0.wp.com/images.303magazine.com/uploads/2022/06/firepit.jpeg?ssl=1",
      },
      {
        name: "Rincon Argentino",
        lat: 40.013841,
        lng: -105.2604569,
        info: "https://www.rinconargentinoboulder.com/wp-content/uploads/2020/03/Rincon_Menu_2020.pdf",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Rincon+Argentino",
        image:
          "https://www.rinconargentinoboulder.com/wp-content/uploads/2015/05/Rincon-Argentino-Storefront-2.jpeg",
        description: "Delicious empanadas not far from the hotel",
      },
      {
        name: "Snarfburger",
        lat: 40.0193449,
        lng: -105.2654746,
        info: "https://www.snarfburger.com/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Snarfburger",
        image:
          "https://media-cdn.tripadvisor.com/media/photo-s/06/ef/14/05/exterior.jpg",
        description: "Good burgers, pretty close to the hotel",
      },
      {
        name: "Moe's Broadway Bagel",
        lat: 40.0198615,
        lng: -105.2652813,
        info: "https://www.moesbagel.com/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Moes+Broadway+Bagel",
        description:
          "Best bagels in town! There are a few Moe's locations in Boulder",
        image:
          "https://media2.westword.com/den/imager/u/golden-m/8672530/update11092016_slentz_26.jpg?cb=1663356846",
      },
    ],
  },
  {
    category: "Drinks",
    icon: "local_bar",
    pois: [
      {
        name: "Upslope",
        lat: 40.0202721,
        lng: -105.2210087,
        info: "https://upslopebrewing.com/",
        description:
          "One of our local favorites! Local, craft beers! Chill vibes",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Upslope+Brewing+Company+South+Flatiron+Court",
        image:
          "https://www.uncovercolorado.com/wp-content/uploads/2016/09/Upslope-Brewing-Company-Boulder-1024x512.jpg",
      },
      {
        name: "Sanitas Brewing Co",
        description: "Local brewery with a great patio, close to the hotel",
        lat: 40.0214502,
        lng: -105.2499745,
        info: "https://sanitasbrewing.com/",
        image:
          " https://i0.wp.com/www.denverpost.com/wp-content/uploads/2022/06/TDP-L-brewery-patio-20220623-jh-049.jpg?fit=620%2C9999px&ssl=1",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Sanitas+Brewing+Co",
      },
      {
        name: "Bitter Bar",
        lat: 40.0161052,
        lng: -105.2868084,
        info: "https://www.thebitterbar.com/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Bitter+Bar",
        description: "Fun place to get cocktails with lounge-y vibes",
        image:
          "https://img.ctykit.com/cdn/co-boulder/images/tr:w-1800/bitter-bar-2022.jpg",
      },
      {
        name: "Vision Quest",
        lat: 40.0262052,
        lng: -105.2498881,
        info: "http://www.visionquestbrewing.com/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Vision+Quest+Brewing",
        description:
          "Wacky vibes and good beer, not far from the hotel, in a weird industrial location.",
        image:
          "https://www.cartogramme.com/wp-content/uploads/2017/04/outside-shot-visionquest.jpg",
      },
    ],
  },
  {
    category: "Coffee",
    icon: "local_cafe",
    pois: [
      {
        name: "Logan's Cafe",
        lat: 40.0476,
        lng: -105.28085,
        info: "https://www.loganscafe.com/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Logan's+Espresso+Cafe",
        description: "Great espresso and lattes in North Boulder",
        image:
          "https://www.localcoffeeshops.org/static/img/shop/colorado-boulder-logans-espresso-cafe.jpeg",
      },
      {
        name: "Red Rocks Coffeehouse",
        lat: 40.03577,
        lng: -105.26053,
        info: "https://www.bouldercoloradousa.com/listings/red-rock-coffeehouse/2124/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Red+Rocks+Coffeehouse",
        description: "More good coffee!",
        image:
          "https://travelboulder.com/wp-content/uploads/2019/01/Red-Rock-Coffee-House.jpg",
      },
      {
        name: "Ozo Coffee",
        lat: 40.0177876,
        lng: -105.2824044,
        info: "https://www.ozocoffee.com/",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Ozo+Coffee",
        description: "Good coffee on Pearl Street",
        image:
          "https://www.ozocoffee.com/wp-content/uploads/2019/05/Pearl-Summer-Patio.jpg",
      },
    ],
  },
  {
    category: "Outside Boulder",
    pois: [
      {
        name: "Rocky Mountain National Park",
        lat: 40.34279,
        lng: -105.68363,
        info: "https://www.nps.gov/romo/index.htm",
        directions:
          "https://www.google.com/maps/dir/?api=1&destination=Rocky+Mountain+National+Park",
        description:
          "Get your fill of nature! Make sure to check the weather, road conditions, etc.",
        image:
          "https://cdn.aarp.net/content/dam/aarp/travel/Domestic/2020/07/1140-rocky-mountain-national-park.jpg",
      },
    ],
  },
];
