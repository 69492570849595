import "./schedule.css";
import Anchor from "../components/anchor";
import { useInView } from "react-intersection-observer";

export default function Schedule({ setNav }: { setNav: (a: string) => void }) {
  type link = {
    name: string;
    info?: string;
    directions: string;
  };

  function event(
    time: string,
    audience: string,
    description: string,
    link?: link
  ) {
    return {
      time: time,
      audience: audience,
      description: description,
      link: link,
    };
  }

  let timeline = [
    {
      day: "Thursday, May 18",
      events: [
        event("9:30am", "Erin and Matt", "", {
          name: "Bear peak hike",
          info: "https://www.alltrails.com/trail/us/colorado/bear-peak-via-the-shadow-canyon-trail",
          directions:
            "https://www.alltrails.com/trail/us/colorado/bear-peak-via-the-shadow-canyon-trail",
        }),
        event("5:30pm", "Everyone", "Dinner at ", {
          name: "Oak",
          directions: "https://www.google.com/maps/dir/?api=1&destination=Oak",
        }),
      ],
    },
    {
      day: "Friday, May 19",
      events: [
        event("9:30am", "Everyone", "Breakfast at", {
          name: "Chautauqua Dining Hall",
          info: "https://www.chautauqua.com/dining/dining-hall/",
          directions:
            "https://www.google.com/maps/dir/?api=1&destination=Chautauqua+Dining+Hall",
        }),
        event("1:00pm", "Matt", "Hair Appointment"),
        event("1:45pm", "Theresa", "Hair appointment at", {
          name: "Drybar",
          info: "https://www.drybar.com/",
          directions:
            "https://www.google.com/maps/dir/?api=1&destination=Drybar",
        }),
        event("2:30pm-4:30pm", "Erin", "Hair and Makeup Appointment"),
        event(
          "5:30pm-6:30pm",
          "Erin, Matt, and Parents",
          "Photos at Chautauqua Park - meet at",
          {
            name: "6th and Baseline",
            directions:
              "https://www.google.com/maps/dir/?api=1&destination=6th+St+%26+Baseline+Rd,+Central+Boulder,+CO+80302",
          }
        ),
        event("7:30pm", "Everyone", "Dinner and Coctails at", {
          name: "Avanti",
          directions:
            "https://www.google.com/maps/dir/?api=1&destination=Avanti",
        }),
      ],
    },
  ];

  let { ref, inView } = useInView({ threshold: 0.5 });
  if (inView) {
    setNav("schedule");
  }

  return (
    <section className="schedule" style={{ maxWidth: "100%" }} ref={ref}>
      <Anchor id="schedule" />
      <h1>Schedule</h1>
      <div style={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
        {timeline.map(({ day, events }) => {
          return (
            <div style={{ flex: "1" }}>
              <h2>{day}</h2>
              <table>
                {events.map((event) => {
                  return (
                    <tr
                      className={
                        event.audience == "Everyone" ? "highlight" : ""
                      }
                    >
                      <td>{event.time}</td>
                      <td>{event.audience}</td>
                      <td>
                        {event.description}{" "}
                        {event.link ? (
                          <span>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={event.link.directions}
                            >
                              {event.link.name}
                            </a>
                          </span>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          );
        })}
      </div>
    </section>
  );
}
